import {request} from '@/utils/request'

// 获取实名
export function getRealName() {
  return request('GET','/shop/users/auth', {}, true)
}

// 实名认证
export function postRealName(data) {
  return request('POST','/shop/users/auth/real', data, true)
}

// 实名认证修改
export function putRealName(data) {
  return request('PUT','/shop/users/auth/real', data, true)
}
