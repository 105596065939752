<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <div class="shop_order_main">

            <userMenu type="41" />

            <div class="shop_order_list">
                <div class="realNameSuccessHead" v-if="type === 1">
                    <img src="../../../../assets/img/success.png" class="icon" />
                    <div class="info">
                        <h1>恭喜！您的实名认证已通过！</h1>
                        <span>实名认证通过后，您可以申请在平台开设店铺、发起交易及发布供应、求购信息</span>
                    </div>
                </div>

                <div class="realNameSuccessHead" v-if="type === 2">
                    <img src="../../../../assets/img/fail.png" class="icon" />
                    <div class="info">
                        <h1>抱歉！您的实名认证审核未通过！</h1>
                        <span>驳回原因：{{denyMessage}}</span>
                    </div>
                </div>

                <div class="shop_form mt40" style="padding-left: 56px">
                    <el-form ref="form" :model="form" :inline="true" :rules="rules" size="small">
                        <div class="realNameContent">
                            <div class="left">
                                <el-form-item label="姓名：" class="mb20" prop="name">
                                    <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
                                </el-form-item>

                                <el-form-item label="证件号：" class="mb20" prop="idNumber">
                                    <el-input v-model="form.idNumber" placeholder="请输入证件号"></el-input>
                                </el-form-item>

                                <el-form-item label="身份证正反面：" class="mb20" prop="fileAddress">
                                    <div class="flexColumn">
                                        <div class="uploadExample">
                                            <el-upload
                                                    class="avatar-uploader"
                                                    :action="uploadURL"
                                                    :before-upload="beforeUploadImage"
                                                    :on-success="idFileUpAddressUpload"
                                                    :show-file-list="false">
                                                <img v-if="form.idFileUpAddress" :src="imageURL + form.idFileUpAddress" class="avatar">
                                                <i v-if="!form.idFileUpAddress" class="el-icon-plus avatar-uploader-icon"></i>
                                                <span v-if="!form.idFileUpAddress" class="text">正面</span>
                                            </el-upload>
                                            <div class="example">
                                                <el-image
                                                        class="avatar" fit="contain"
                                                        :src="require('../../../../assets/img/example/example_img02.png')"
                                                        :preview-src-list="[require('../../../../assets/img/example/example_img02.png')]">
                                                </el-image>
                                                <div class="desc">示例图<img src="../../../../assets/img/imgShow.png"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flexColumn mt20">
                                        <div class="uploadExample">
                                            <el-upload
                                                    class="avatar-uploader"
                                                    :action="uploadURL"
                                                    :before-upload="beforeUploadImage"
                                                    :on-success="idFileDownAddressUpload"
                                                    :show-file-list="false">
                                                <img v-if="form.idFileDownAddress" :src="imageURL + form.idFileDownAddress" class="avatar">
                                                <i v-if="!form.idFileDownAddress" class="el-icon-plus avatar-uploader-icon"></i>
                                                <span v-if="!form.idFileDownAddress" class="text">背面</span>
                                            </el-upload>
                                            <div class="example">
                                                <el-image
                                                        class="avatar" fit="contain"
                                                        :src="require('../../../../assets/img/example/example_img03.png')"
                                                        :preview-src-list="[require('../../../../assets/img/example/example_img03.png')]">
                                                </el-image>
                                                <div class="desc">示例图<img src="../../../../assets/img/imgShow.png"></div>
                                            </div>
                                        </div>
                                        <div class="el-upload__tip">支持扩展名：.jpg、.jpeg、.png格式，图片大小不超过10M，正反面照片分别上传</div>
                                    </div>
                                </el-form-item>
                            </div>

                            <!--                            <div class="right">-->
                            <!--                                <el-form-item label="身份证有效期：" class="mb20" prop="phone">-->
                            <!--                                    <el-date-picker-->
                            <!--                                            v-model="form.phone"-->
                            <!--                                            type="date"-->
                            <!--                                            value-format="yyyy-MM-dd"-->
                            <!--                                            placeholder="选择身份证有效期">-->
                            <!--                                    </el-date-picker>-->
                            <!--                                </el-form-item>-->


                            <!--                            </div>-->
                        </div>

                        <el-form-item class="mt24 mr0" style="width: 100%">
                            <el-button type="primary" :loading="submitLoading" @click="toSubmitForm('form')">提交</el-button>
                            <el-button type="default" @click="$router.go(-1)">返回</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {imageURL, uploadURL} from "../../../../utils/config";
  import {beforeUploadImage} from "../../../../utils/method";
  import {getRealName, putRealName} from "../../../../api/shop/realName";
  const userMenu = () => import('../../../../components/layout/shop/userMenu');
  export default {
    name: "realNameEdit",
    data () {
      let validateFileAddress = (rule, value, callback) => {
        if (this.form.idFileUpAddress === '' || this.form.idFileDownAddress === '') {
          callback(new Error('请上传身份证'));
        } else {
          callback();
        }
      };
      return {
        type: 1,
        form: {
          name: '',
          idNumber: '',
          idFileUpAddress: '',
          idFileDownAddress: '',
          id: ''
        },
        denyMessage: '',
        rules: {
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' }
          ],
          idNumber: [
            { required: true, message: '请输入证件号', trigger: 'blur' },
            { pattern: '^[1-9]\\d{7}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}$|^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}([0-9]|X)$', message: '请输入正确的证件号', trigger: 'blur'}
          ],
          fileAddress: [
            { validator: validateFileAddress, required: true, trigger: 'blur' }
          ]
        },
        submitLoading: false,
        imageURL: imageURL,
        uploadURL: uploadURL,
        beforeUploadImage: beforeUploadImage
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getRealName()
    },
    methods: {
      getRealName () {
        getRealName().then(res => {
          if (res.code === 0) {
            if (res.data.auditStatus === 1) {
              this.type = 1
            }
            if (res.data.auditStatus === 2) {
              this.type = 2;
              this.denyMessage = res.data.denyMessage
            }
            this.form.name = res.data.name
            this.form.idNumber = res.data.idNumber
            this.form.idFileUpAddress = res.data.idFileUpAddress
            this.form.idFileDownAddress = res.data.idFileDownAddress
            this.form.id = res.data.id
          }
        })
      },
      idFileUpAddressUpload (res) {
        if (res.code === 0) {
          this.form.idFileUpAddress = res.data
        }
      },
      idFileDownAddressUpload (res) {
        if (res.code === 0) {
          this.form.idFileDownAddress = res.data
        }
      },
      toSubmitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            putRealName({
              data: this.form
            }).then(res => {
              this.submitLoading = false;
              if (res.code === 0) {
                this.$message({
                  message: '实名认证提交成功',
                  showClose: true,
                  type: 'success'
                })
                this.$router.go(-1);
              }
            }).catch(() => {
              this.submitLoading = false;
            })
          } else {
            return false;
          }
        });
      }
    },
    components: {
      userMenu
    }
  }
</script>
